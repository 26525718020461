@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

.heading {
  font-family: "Poppins", sans-serif;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.01), 0 2px 4px -2px rgb(0 0 0 / 0.01);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.02),
    0 4px 6px -4px rgb(0 0 0 / 0.02) !important;
}

.fancy-list>li {
  @apply mt-3
}

.fancy-list>li::before {
  content: '▹';
  position: absolute;
  left: 0;

  @apply text-emerald-600 dark:text-emerald-400
}

.fancy-list-projects>li {
  @apply mt-4
}

.fancy-list-projects>li::before {
  content: '▹';
  position: relative;
  margin-right: 0.5rem;
  left: 0;

  @apply text-emerald-600 dark:text-emerald-400
}


.styledPic {
  position: relative;
  max-width: 600px;
  margin: 50px auto 0;
  width: 70%;
}

.styledPic>.wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.wrapper::after {
  @apply border-2 border-emerald-600 dark:border-emerald-400 block w-full h-full absolute rounded-lg transition-all;
  content: "";
  top: 20px;
  left: 20px;
  z-index: -1;
}

.fancy-link {
  display: inline-block;
  @apply text-emerald-600 dark:text-emerald-400
}

.fancy-link::after {
  content: '';
  width: 0;
  height: 2px;
  display: block;
  transition: 300ms;
  @apply bg-emerald-600 dark:bg-emerald-400
}

.fancy-link:hover::after {
  width: 100%;
}


.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {

  transform: translateX(0%);
}

.slide-exit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(-100%);
}

.slide-exit-active {
  transform: translateX(-100%);
}